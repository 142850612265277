import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const DOReversal = Loadable(lazy(() => import("./doReversalUpdate")));
const DOReversalRoutes = [
    {
        path: '/doReversal',
        element: <DOReversal />,
    }
]

export default DOReversalRoutes
