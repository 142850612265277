import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
const SalePurchaseNSReport = Loadable(lazy(() => import("./salePurchaseNSReport")));
const SalePurchaseColumnSel = Loadable(lazy(() => import("./SalePurchaseColumnSel")));


const SalePurchaseNSReportRoute = [
    {
        path: '/salePurchaseNSReport',
        element: <SalePurchaseNSReport />,
    },
    {
        path: '/salePurchaseColumnSel',
        element: <SalePurchaseColumnSel />,
    },
]

export default SalePurchaseNSReportRoute