import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
// const Order = Loadable(lazy(() => import('./Order')))
const Order = Loadable(lazy(() => import('./OrderNew')))
const Orderadd = Loadable(lazy(() => import('./Orderadd')))
const OrderaddNew = Loadable(lazy(() => import('./orderAddNew')))
const Orderview = Loadable(lazy(() => import('./Orderview')))
const Orderstatus = Loadable(lazy(() => import('./Orderstatus')))
const Orderdelivery = Loadable(lazy(() => import('./Orderdelivery')))
const Yardview = Loadable(lazy(() => import('./Yardview')))
const LogisticTeamOrder = Loadable(lazy(() => import('./LogisticTeamOrder')))
const OrderRoutes = [
    {
        path: '/order_loaded',
        element: <Order />,
    },
    {
        path: '/order_loaded/add',
        element: <Orderadd />,
    },
    {
        path: '/order_loaded/addNew',
        element: <OrderaddNew />,
    },
    {
        path: '/order_loaded/edit/:orderid',
        element: <Orderstatus />,
    },
    {
        path: '/order_loaded/view/:orderid',
        element: <Orderview />,
    },
    {
        path: '/order_loaded/view/:orderid/:name',
        element: <Orderview />,
    },
    {
        path: '/order_loaded/changestatus/:orderid',
        element: <Orderstatus />,
    },
    {
        path: '/order_loaded/bmApproval/:orderid',
        element: <Orderstatus />,
    },
    {
        path: '/yard/yardview/:orderid',
        element: <Yardview />,
    },
    {
        path: '/order_loaded/delivery/:orderid',
        element: <Orderdelivery />,
    },
    {
        path: '/order_loaded/logistic-delivery/:orderid',
        element: <LogisticTeamOrder />,
    },
]

export default OrderRoutes
